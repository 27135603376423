import React from 'react'

const TabularAISection = () => {
  return (
    <>

      <div className="scroll-view-component scrollbar-secondary-component">
        <div className="content-wrapper">
          <div className="container-xxl flex-grow-1">
            <h4 className="fw-bold mt-3  mb-4"><span className="text-muted fw-light"></span>Tabular AI</h4>



          </div>
        </div>
      </div>

    </>
  )
}

export default TabularAISection